.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    color: inherit;
    line-height: 24px;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    word-wrap: normal;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

.md-icon-2x {
    font-size: 2.8rem;
    width: 38px;
    height: 38px;
    line-height: 38px;
}
.md-icon-3x {
    font-size: 4.2rem;
    width: 58px;
    height: 58px;
    line-height: 58px;
}
.md-icon-dark {
    color: rgba(0, 0, 0, 1)!important;
}
.md-icon-light {
    color: rgba(255, 255, 255, 1)!important;
}
