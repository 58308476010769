.documest {
    &__list {
        width: 100%;
        display: flex;
        height: 30px;
        background-color: #e9e9e9;

        &--vertical {
            height: auto;
            background-color: #ffffff;
            flex-direction: column;
            overflow-y: auto;
            max-height: 260px; // calc(100vh - 520px)
        }

        &-header {
            line-height: 40px;
            font-weight: bold;
        }

        &-item {
            display: flex;
            flex: 1 1 auto;
            height: auto;
            padding: 0 15px;
            align-items: center;
            cursor: pointer;
            outline: none;

            .documest__list--vertical & {
                flex: 1 0 40px;
            }

            &--selected {
                background-color: $color-documest;
                color: white;
            }
        }

        &--fixed-top {
            position: absolute;
            width: calc(100% - 400px);
            z-index: 10;
        }
    }

    &__check {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        height: calc(100vh - 62px);
        min-width: calc(100% - 400px);
        background-color: white;
        padding: 10px;
        justify-content: center;
        align-items: center;

        img {
            max-width: 90%;
            max-height: 90%;
        }

        &-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
        }

        // user actions
        &-actions {
            width: 400px;
            min-width: 400px;
            display: flex;
            background-color: #f4f4f4;
            padding: 10px;
            flex-direction: column;
            justify-content: space-between;

            &--kyc {
                width: 100%;
                min-width: initial;
                background-color: inherit;
                padding: 0;
            }
        }

        &-buttons {
            display: flex;

            .md-button {
                flex: 1;
            }
        }

        &-message {
            height: 30px;
            padding: 0 2px;
            color: #d32f2f
        }
    }

    &__user {
        display: flex;

        table {
            width: 100%;
            border-collapse: collapse;
        }

        th,
        td {
            padding: 3px;
            border-left: solid 1px #aaaaaa;
            border-bottom: solid 1px #aaaaaa;
            word-break: break-all;
            text-align: left;

            &:first-of-type {
                border-left: 0;
            }
        }
    }

    &__view {
        font-size: 16px;
        height: calc(100vh - 62px);
        background-color: white;
        padding: 10px;

        &--multi-files {
            position: relative;
            height: calc(100vh - 92px);
            overflow-y: auto;
        }

        img {
            max-width: 100%;
        }

        pre {
            margin: 0;
        }
    }

    &__pdf-embed {
        position: absolute;
        width: 100%;
        height: calc(100vh - 62px);
        border: 0;

        &--multi-files {
            position: absolute;
            height: calc(100vh - 92px);
            overflow-y: auto;
        }
    }

    &__pdf-preview {
        padding: 0;
    }

    &__code {
        margin: 0;
        width: 100%;
        height: calc(100vh - 62px);
        overflow-y: auto;
        white-space: pre-wrap;
    }


}

.img-upload {

    &__wrapper {
        position: relative;
        width: 100%;
        height: 152px;
        line-height: 152px;
        color: rgba(0, 0, 0, 0.38);
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);

        &--has-document-id {
            height: 215px;
            line-height: 215px;
        }
        .md-input-invalid & {
            border-bottom-color: rgb(221, 44, 0);
        }
    }

    &__label {
        font-size: 75%;
        padding-left: 4px;
    }

    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &-item {
            position: relative;
            flex: 1 1 1%;

            & + & {
                border-left: solid 1px rgba(0, 0, 0, 0.12);
            }
        }

        &-name {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            height: 20px;
            line-height: 20px;
            z-index: 0;
        }
    }

    &__thumbnail,
    &__action {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: auto 130px;
        cursor: pointer;
        z-index: 1;

        &--has-document-id {
            background-size: auto 190px;
        }

        .material-icons {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size:60px;
            width:60px;
            height:60px;
            line-height:60px;
            transform: translate(-50%, -50%);
        }
    }
}
.compare-value {
    &__td {
        & + & {
            border-top: solid 1px rgba(0, 0, 0, 0.25);
        }

        &--current {
            //position: relative;
            //font-weight: 700;
            //min-height: 15px;
        }

        &--previous {
            //opacity: 0.8;
            //font-size: 90%;
        }
    }
    &__value {

        &--match {
            color: #0bb000;
        }

        &--not-match {
            color: #f86000;
        }

        .compare-value__td--no-data & {
            &::after {
                content: "—";
                font-weight: normal;
            }
        }
    }
}

.documest-data {

    &__wrapper {
        background: #ffffff;
        min-height: calc(100vh - 62px);
    }

    &__container {
        padding: 10px;

        &--suggested {
            border-right: solid 1px rgba(0,0,0,0.12);
            min-height: calc(100vh - 62px);
        }
    }

    &__title {
        //line-height: 20px;
        font-weight: bold;
        border-bottom: solid 1px rgba(0,0,0,0.12);
        padding: 3px;

        &--main {
            padding-top: 10px;
            font-size: 18px;
            padding-bottom: 5px;
            border-bottom: solid 1px #aaaaaa;
        }
    }

    &__content {
        padding: 10px 0;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;

        &-button {
            margin: 0;
        }

        &--no-padding {
            padding: 0;
        }
    }

    &__input {

        border-bottom: solid 1px rgba(0,0,0,0.12);

        &--search {
            md-autocomplete-wrap {
                box-shadow: none !important;
            }
        }
    }
}

.documest {

    &-table {
        display: flex;

        table {
            width: 100%;
            border-collapse: collapse;
        }

        th,
        td {
            padding: 3px;
            border-bottom: solid 1px rgba(0,0,0,0.12);
            word-break: break-all;

            &:first-of-type {
                width: 150px;
                border-left: 0;
            }
        }

        th {
            text-align: left;
            position: relative;
        }

        &__header-action {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            margin-right: 10px;
            .md-raised {
                &.md-primary {
                    padding: 0;
                    min-height: 12px !important;
                    margin: 0 0 5px 0;
                    font-size: 10px;
                    line-height: 10px;
                    height: 20px;
                }
            }
        }

        &--success {
            font-weight: bold;
            color: #0bb000;
        }

        &--bordered {
            td,
            th {
                border-left: solid 1px rgba(0,0,0,0.12);
            }
        }

        &__action {
            border-left: solid 1px rgba(0,0,0,0.12);
            width: 100px;
            text-align: center;

            .md-icon-button {
                padding: 0;
                width: 24px;
                height: 24px;
                min-height: 24px;
                margin: 0;
            }
        }
    }
}

cp-table {
    .table {
        .tr {
            .td.type-md-icon-button-dialog-table {
                padding: 0px;
                .md-icon-button-dialog-table {
                    padding: 0px 30px;
                    margin: 0px 6px;
                    width: 24px;
                    height: 24px;
                    min-height: 24px;
                }
            }
        }
    }
}
