// BASE
//  Everything that only begins with an element (base styling)
//  belongs here.
//  You can also set classes to your elements, which you want to reuse
//  eg: a, .link{}
// -----------------------------------

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Password-Input';
    src: url('../fonts/password/password.ttf');
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/material/MaterialIcons-Regular.eot'); /* For IE6-8 */
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url('../fonts/material/MaterialIcons-Regular.woff2') format('woff2'),
    url('../fonts/material/MaterialIcons-Regular.woff') format('woff'),
    url('../fonts/material/MaterialIcons-Regular.ttf') format('truetype')
}

@-webkit-keyframes glowing {
    0% { background-color: #d32f2f; box-shadow: 0 0 3px #f44336 }
    50% { background-color: #b71c1c; box-shadow: 0 0 20px #d32f2f; }
    100% { background-color: #d32f2f; box-shadow: 0 0 3px #f44336; }
}

@-moz-keyframes glowing {
    0% { background-color: #d32f2f; box-shadow: 0 0 3px #f44336 }
    50% { background-color: #b71c1c; box-shadow: 0 0 20px #d32f2f; }
    100% { background-color: #d32f2f; box-shadow: 0 0 3px #f44336; }
}

@-o-keyframes glowing {
    0% { background-color: #d32f2f; box-shadow: 0 0 3px #f44336 }
    50% { background-color: #b71c1c; box-shadow: 0 0 20px #d32f2f; }
    100% { background-color: #d32f2f; box-shadow: 0 0 3px #f44336; }
}

@keyframes glowing {
    0% { background-color: #d32f2f; box-shadow: 0 0 3px #f44336 }
    50% { background-color: #b71c1c; box-shadow: 0 0 20px #d32f2f; }
    100% { background-color: #d32f2f; box-shadow: 0 0 3px #f44336; }
}

html,
body {
    height: auto;
    min-height: 100vh;
}
html {
    font-size: 62.5%;
}

body {
    font-family: Roboto, Arial, sans-serif;
    font-size: $font-size-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto, Arial, sans-serif;
    font-weight: 400 !important;
}

section,
div {
    box-sizing: border-box;
    outline-color: $color-accent;
}

a {
    cursor: pointer;
    outline: 0;
}

label.small {
    font-size: $font-size-base * .75;
    color: rgba(0, 0, 0, 0.54);
}

p {
    margin: 0;
}

// LAYOUT
// -----------------------------------

.site-login {
    padding-bottom: 78px;

    header {
        padding: 16px;
        margin-bottom: 16px;
        text-align: center;
        color: white;
        background: black;

        & > div {
            margin: 0 auto;
            max-width: 400px;

            h1 {
                margin: 0;
                span {
                    display: none;
                }
            }

        }
    }



    form {
        margin: 0 auto;
        padding: 8px;
        max-width: 432px;
        background: white;

        .error-message {
            color: #F44336;
        }
    }
}

#dashboard {

    #main-nav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        padding: 0 1rem;
        height: $navbar-height;
        background: black;
        box-shadow: 0 1px 4px rgba(0,0,0,.54);

        md-progress-circular.md-default-theme path, md-progress-circular path {
            stroke: white !important;
        }

        md-tabs {
            min-height: 0;
        }
        md-tabs-canvas,
        md-pagination-wrapper {
            line-height: $navbar-height;
            height: $navbar-height;
            background: none;
        }
        md-tabs-wrapper {
            background: none;

            md-prev-button,
            md-next-button {
                background: none;

                md-icon {
                    color: $color-primary;
                }
            }
        }

        md-ink-bar {
            color: white;
            background: white;
        }

        md-tab-item {
            padding: 0;
            min-width: 60px;

            & > div {
                position: relative;
                padding: 3px 4px;
                outline: none;

                &.inactive {
                    z-index: 1;
                    background: black;
                }

                .close {
                    display: none;
                    position: absolute;
                    top: 3px;
                    width: 24px;
                                        text-align: center;
                    background: black;
                    cursor: pointer;
                    outline: none;

                    md-icon {
                        margin: 0;
                        color: inherit;
                    }
                }

                &:hover .close {
                    display: block;
                    cursor: pointer;
                }
            }
            md-icon {
                margin-right: 5px;
                color: inherit;
            }
        }


        .md-button {
            margin: 3px 2px 0 0;
            line-height: $navbar-height - 0.6rem;
            height: $navbar-height - 0.6rem;
            min-width: 0;
            min-height: 0;
            color: white;
        }

        span.title {
            padding-left: 6px;
            font-size: 1.4rem;
            line-height: $navbar-height;
            color: $color-primary;
        }

        .warning {
            margin: 0 -1rem 0 1rem;
            padding: 0 0.5rem;
            text-transform: uppercase;
            line-height: 3rem;
            color: white;
            background: red;
        }

        .md-menu {
            padding: 0;
        }

    }

    #main-content {
        height: 100vh;
        padding: $navbar-height 0;
        overflow: auto;

        .widgets {
            text-align: center;
            .widget {
                position: relative;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                width: 200px;
                height: 200px;
                text-shadow: 0 1px 2px rgba(0, 0, 0, .54);
                color: white;
                background: $color-accent;
                border-radius: 2px;
                overflow: hidden;
                box-shadow: 0 1px 8px 0 rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.12);
                @include transition(all, .2s, linear);


                &:hover {
                    text-shadow: 0 8px 8px rgba(0, 0, 0, .54);
                    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
                }

                h3 {
                    margin: 0;
                    height: 4.8rem;
                    line-height:4.8rem;
                    text-transform: uppercase;
                    background: rgba(0, 0, 0, 0.18);
                    overflow: hidden;
                }


                figure {
                    margin: 32px 8px 0 8px;

                    h4 {
                        margin: 10px 0;
                        font-size: 1.7rem;
                    }
                    md-icon {
                        font-size: 4rem;
                        height: 4rem;
                        width: 4rem;
                    }
                }

            }
        }
    }

    .drop-areas {
        position: fixed !important;
        top: $navbar-height;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: calc(100% - #{$navbar-height});
        z-index: 9020;
        .area {
            float: left;
            width: calc(100% / 3);
            height: calc(100% / 3);
        }
        // visible grid, placed under the actual droppable grid
        &.visible {
            background: rgba(0, 0, 0, 0.2);
            z-index: 9000;
            .area {
                border: #333 0.1rem solid;
            }

        }
    }

    #win-placeholder {
        position: fixed;
        border: $color-primary 0.2rem dashed;
        border-radius: 1rem;
        background-color: $color-accent;
        opacity: 0.6;
        z-index: 9010;
    }

    .win {
        position: fixed;
        border: $color-accent 0.1rem solid;
        border-radius: 0.3rem;
        background: white;
        box-shadow: 0 0.3rem 0.9rem $color-shadow;
        overflow: auto;

        header {
            position: relative;
            padding: 0 1rem;
            height: 30px;
            min-height: 30px;
            color: white;
            background: $color-accent;

            h1 {
                margin: 0;
                line-height: 3rem;
                font-size: $font-size-base;

                span {
                    font-size: $font-size-small;
                }
            }

            .md-button {
                &:first-of-type {
                    margin-left: -6px;
                }

                margin: 0;
                width: auto;
                min-width: 0;
                height: auto;
                min-height: 3rem;
                line-height: 2.4rem;
                color: inherit;

                &:hover,
                &:focus {
                    color: rgba(255,255,255,.8) !important;
                }

                md-icon {
                    color: inherit;
                }

            }

            .md-menu {
                padding: 0;
            }

        }

        & > div {
            min-height: calc(100% - 30px);

            md-content {
                cursor: auto;
                width: 100%;

                md-progress-linear[md-mode=indeterminate] .md-container {
                    top: 0;
                }

                .message {
                    padding: 5px 10px;
                    color: black;
                }

                .win-search {
                    .md-primary {
                        &.glow {
                            -webkit-animation: glowing 1.5s infinite;
                            -moz-animation: glowing 1.5s infinite;
                            -o-animation: glowing 1.5s infinite;
                            animation: glowing 1.5s infinite;
                        }
                    }

                    .change-message {
                        padding: 0 2px;
                        color: #d32f2f;

                        .md-icon-button {
                            margin: 0;
                            padding: 0;
                            min-height: 24px;
                            height: 24px;
                        }
                    }
                }

                .button-group {
                    button {
                        margin: $grid-gutter-width/2 $grid-gutter-width/4;
                    }
                    md-input-container {
                        margin-bottom: 0;
                    }

                }
                md-switch {
                    margin: 0;
                    .md-container {
                        cursor: grab;
                        width: 2.4rem;
                        height: 1.6rem;
                        position: relative;
                        -moz-user-select: none;
                        margin-right: 0.8rem;

                    }
                    .md-bar {
                        left: 0.1rem;
                        width: 3.4rem;
                        top: 0.5rem;
                        height: 0.6rem;
                        border-radius: 0.8rem;
                        position: absolute;
                        background: lightgray;
                    }
                    .md-thumb {
                        width: 1.2rem;
                        height: 1.2rem;
                        background: #848484;
                    }
                    .md-thumb-container {
                        width: 2.6rem;
                    }

                    &.md-checked {
                        .md-thumb {
                            background: #FDD835;
                        }
                        .md-bar {
                            background: #FEEFAE;
                        }
                    }

                }
            }
        }
    }

    .position-top-left {
        top: $navbar-height;
        left: 0;
        height: calc(50% - #{$navbar-height / 2});
        width: 50%;
    }
    .position-top {
        top: $navbar-height;
        left: 0;
        height: calc(50% - #{$navbar-height / 2});
        width: 100%;
    }
    .position-top-right {
        top: $navbar-height;
        right: 0;
        height: calc(50% - #{$navbar-height / 2});
        width: 50%;
    }
    .position-left {
        top: $navbar-height;
        left: 0;
        height: calc(100% - #{$navbar-height});
        width: 50%;
    }
    .position-center {
        top: $navbar-height;
        left: 0;
        height: calc(100% - #{$navbar-height});
        width: 100%;
    }
    .position-right {
        top: $navbar-height;
        right: 0;
        height: calc(100% - #{$navbar-height});
        width: 50%;
    }
    .position-bottom-left {
        bottom: 0;
        left: 0;
        height: calc(50% - #{$navbar-height / 2});
        width: 50%;
    }
    .position-bottom {
        bottom: 0;
        left: 0;
        height: calc(50% - #{$navbar-height / 2});
        width: 100%;
    }
    .position-bottom-right {
        bottom: 0;
        right: 0;
        height: calc(50% - #{$navbar-height / 2});
        width: 50%;
    }
    .full-size {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

    }

}

#win-drag-image,
#page-leave-confirmation
{
    display: none;
}

.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 48px;
    line-height:48px;
    text-align: center;
    color: white;
    background-color: #616161;
    border-top: 3px solid $color-accent;
    box-shadow: inset 0 3px 3px $color-accent;

    .languages {
        position: absolute;
        right: 8px;
        bottom: 24px;

        .md-button {
            padding: 0;

            .flag {
                margin: 8px auto;
                width: 24px;
                height: 24px;
                background: no-repeat center center / 36px 24px;
                border-radius: 50%;
            }
        }


    }
}

[draggable] {
    @include user-select(none);
    -webkit-user-drag: element;
    cursor: move;
}

md-input-container.page-limit {
    margin-right: 10px;
    width: 150px;
}

cp-pagination {
    ul {
        list-style: none;
        margin: 0;
        padding: 1.8rem 1.0rem 1.0rem 1.5rem;

        li {
            display: inline-block;
            padding: 0.5rem 1rem 0.3rem 1rem;
            cursor: pointer;
            color: $color-accent;
            box-sizing: border-box;
            border-bottom: transparent 0.3rem solid;
            outline: 0;

            &.active,
            &:hover {
                color: black;
                border-bottom-color: $color-accent;
            }
        }
    }

    md-input-container {
        min-width: 100px;
    }
}

.edit-table {
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
    overflow: auto;

    table {
        min-width: 100%;
        border-collapse: collapse;

        tr:nth-of-type(odd) {
            background-color: #f5f5f5;
        }
        tr:hover {
            background-color: #e0e0e0;
        }

        &,
        th,
        td {
            border: #ccc 0.1rem solid;
        }

        th,
        td {
            padding: 0.3rem 0.5rem;
            min-width: 1rem;
            max-width: 40rem;
            vertical-align: top;
            font-weight: normal;
            border-width: 0 0 0.1rem 0.1rem;
            outline-color: $color-accent;
        }
        th {
            padding: 0.5rem;
            background: #e0e0e0;
            white-space: nowrap;

            & > a {
                text-decoration: underline;

                &[disabled] {
                    cursor: default;
                    text-decoration: none;
                }

            }

            .mass-update-icon {
                float: right;
                cursor: pointer;
            }

        }

        td {
            a,
            &.link {
                text-decoration: underline;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;

                &[disabled] {
                    text-decoration: none;
                    cursor: default;
                }
            }

            &.editable {
                position: relative;
                background: rgba(224, 242, 241, .8);

                &.alt {
                    background: rgba(224, 242, 241, .3);
                }

                &:hover {
                    background: rgba(178, 223, 219, 8);

                }

                & > textarea.dynamic,
                & > input.dynamic {
                    display: block;
                    border: none;
                    outline: none;
                    box-sizing: border-box;

                    &.invalid {
                        border: #ff8a80 2px solid;
                        border-radius: 2px;
                    }
                }
                & > textarea.dynamic {
                    margin: -0.3rem -0.5rem !important;
                    width: calc(100% + 1rem);
                    height: calc(100% + 1rem);
                    min-height: 40px;
                }
                & > input.dynamic {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                }

                md-autocomplete {
                    height: 23px;

                    md-autocomplete-wrap {
                        height: 23px;

                        input {
                            height: 23px;
                            line-height: 2.3rem;
                        }
                    }

                    button {
                        margin: 0;
                        width: 23px;
                        height: 23px;
                    }
                }
            }

            &.edited {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-style: solid;
                    border-width: 0 10px 10px 0;
                    border-color: transparent rgba(255, 138, 128, .66) transparent transparent;
                }

            }
            &.number, &.date {
                text-align: right;
            }
            &.align-center {
                text-align: center;
            }

            &.yellow-color {
                color: rgb(243, 154, 10) !important;
            }
            &.orange-color {
                color: rgb(243, 154, 10) !important;
            }
            &.red-color {
                color: red !important;
            }
            &.cyan-color {
                color: rgb(0, 215, 239) !important;
            }

            &.type-md-icon-button,
            &.type-inline-action {
                padding: 0;
                min-width: 0;
                width: 32px;
                text-align: center;

                .md-icon-button {
                    margin: 0;
                    padding: 0;
                    width: 24px;
                    height: 24px;
                    min-height: 0;
                    line-height:24px;
                }
            }

            [contenteditable] {
                margin: -0.3rem -0.5rem;
                padding: 0.3rem 0.5rem;
                outline: 0;
            }

            md-autocomplete {
                margin: -0.3rem -0.5rem;
                border-radius: 0;
                background: none;

                md-autocomplete-wrap {
                    box-shadow: none;
                }
            }

            md-switch {
                margin: -0.3rem -0.5rem !important;
            }

            md-select {
                margin: -0.3rem -0.5rem;
                height: 25px;

                &.md-default-theme .md-select-value,
                .md-select-value {
                    min-height: 0;
                    border: none;
                }
            }
            .cp-switch-label {
                margin: 2px auto 8px auto;
            }
            [cp-editable] {
                width: 100%;
                min-height: 1.6rem;

                & + [type="number"] {
                    text-align: right;
                }
            }

            cp-datetime-picker {
                input {
                    border: none;
                    background: none;
                    outline: none;
                }

                .md-icon-button {
                    margin: 0 1px;
                    padding: 0;
                    width: 24px;
                    height: 24px;
                    min-height: 24px;
                }
            }
        }
    }
}

table.list,
table.view {
    min-width: 100%;

    th,
    td {
        padding: 0.5rem;
        vertical-align: top;
        font-weight: normal;
        border-top: 0.1rem solid #ccc;

        &.number, &.date {
            text-align: right;
        }
    }

    thead {
        th {
            border-bottom: 0.1rem solid #ccc;
            background: #e0e0e0;
        }
    }

    tbody {

        tr:nth-of-type(odd) {
            background-color: #f9f9f9;
        }
        tr:hover {
            background-color: #eee;
        }

        th {
            width: 25%;
            text-align: left;
        }

        td {
            &.action {
                width: 5rem;
            }
        }
    }

    .md-button {
        margin: 0;
        min-width: 3.6rem;
    }
}

// Used for md-switch
cp-switch-container {
    label {
        display: block;
        margin-bottom: 8px;
        font-size: .75em;
        color: rgba(0, 0, 0, .54);
    }
}
// Custom switch
.cp-switch {
    display: none;
}
.cp-switch-label {
    position: relative;
    top: 4px;
    display: block;
    height: 8px;
    width: 36px;
    background: #bdbdbd;
    border-radius: 100px;
    cursor: pointer;
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
}
.cp-switch-label:after {
    content: '';
    position: absolute;
    left: -4px;
    top: -4px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    background: #9E9E9E;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
}
.cp-switch:checked + label {
    background: lighten($color-primary, 15%);
}
.cp-switch:checked + label:after {
    right: -4px;
    left: auto;
    background: $color-primary;
}
.cp-switch:disabled + label {
    background: #d5d5d5;
    pointer-events: none;
}
.cp-switch:disabled + label:after {
    background: #bcbdbc;
}

cp-treeview {
    ul {
        padding: 0;

        li {
            line-height: 40px;

            & > div {
                padding-left: 8px;
                outline: none;
                cursor: pointer;

                & > span {
                    outline: none;

                    &.action {
                        text-decoration: underline;
                        outline: none;
                        cursor: pointer;
                    }
                }
            }

            ul {
                background-color: rgba(0,0,0,.05);

                li {
                    & > div {
                        padding-left: 24px;
                    }

                    li > div {
                        padding-left: 40px;
                    }
                }
            }
        }
    }
}

cp-content-treeview {
    md-list {
        padding: 0;
    }
}

md-input-container {
    cp-datetime-picker {
        .md-button.md-icon-button {
            -webkit-order: 3;
            order: 3;
            margin: 0 1px;
            padding: 0;
            width: 24px;
            height: 24px;
            min-height: 24px;
        }
    }
}

md-toast.share-url {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    z-index: 999999;
    width: auto;

    .md-toast-content {
        position: relative;
        padding: 0 0 0 15px;
        background: #f5f5f5;

        input {
            width: 300px;
        }

        input,
        md-icon {
            color: $color-accent;
        }

        .md-icon-button {
            margin: 0;
        }
    }

}
[cp-dialog] {
    .current-percent {
        float: right;
        margin-left: 16px;
        width: 140px;
        height: 140px;
        line-height:130px;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        text-overflow: ellipsis;
        color: #4CAF50;
        border: #4CAF50 5px solid;
        border-radius: 50%;
        overflow:hidden;

        &.risk {
            color: #E53935;
            border-color: #E53935;
        }
    }
}

.grid-tiles {
    & > div {
        position: relative;

        .help-button {
            margin: 16px 0 0 0;
        }
    }
}

.cp-tooltip {
    position: absolute;
    top: 100%;
    right: 8px;
    z-index: 10;
    padding: 8px;
    width: calc(100% - 16px);
    line-height: 1.6rem;
    font-size: 1.2rem;
    text-transform: none;
    text-align: left;
    color: white;
    background: rgba(0,0,0,.7);
    border-radius: 3px;
    white-space: normal;

    a {
        color: $color-primary;
    }

    &:after {
        position: absolute;
        bottom: 100%;
        right: 10px;
        content: '';
        height: 0;
        width: 0;
        border: transparent 6px solid;
        border-bottom-color: rgba(0,0,0,.7);
    }

    &.header {
        top: calc(100% + 8px);
        max-width: 400px;

        &:after {
            right: 86px;
        }
    }
}

.code {
    font-family: "Courier New", Courier, monospace;
}

#password-input {
    font-family: 'Password-Input';
}