@media (min-width: $breakSm) {
    .grid-tiles {
        @include clearfix;

        .separator {
            margin-top: 4px;
            width: 100%;
            height: 1px;
            background: rgba(0,0,0,0.26);
        }
    }
    .grid-tile-1,
    .grid-tile-2,
    .grid-tile-3,
    .grid-tile-4 {
        float: left;
        padding: 0 4px 8px 4px;
        width: 25%;
        min-height: 88px;

        md-input-container {
            margin-bottom: 0;

            .md-errors-spacer {
                min-height: 0;
            }
        }

        .md-button {
            margin-top: 12px;
            margin-bottom: 0;
            height: 36px;
        }
    }
    .grid-tile-2 {
        width: 50%;
    }
    .grid-tile-3 {
        width: 75%;
    }
    .grid-tile-4 {
        width: 100%;
        min-height: 0;
    }
}
