#dashboard {
    #main-content .widget.access,
    .win.access header,
    .win.access .md-button.md-primary.md-raised,
    .win.access .md-fab.md-primary.md-raised {
        color: white;
        background: $color-access;
    }
    #main-nav .access {
        color: lighten($color-access, 10%);
    }

    #main-content .widget.support,
    .win.support header,
    .win.support .md-button.md-primary.md-raised,
    .win.support .md-fab.md-primary.md-raised {
        color: white;
        background: $color-support;
    }
    #main-nav .support {
        color: lighten($color-support, 10%);
    }

    #main-content .widget.documest,
    .win.documest header,
    .win.documest .md-button.md-primary.md-raised,
    .win.documest .md-fab.md-primary.md-raised {
        color: white;
        background: $color-documest;
    }
    #main-nav .documest {
        color: lighten($color-documest, 10%);
    }

    #main-content .widget.translator,
    .win.translator header,
    .win.translator .md-button.md-primary.md-raised,
    .win.translator .md-fab.md-primary.md-raised {
        color: white;
        background: $color-translator;
    }
    #main-nav .documest {
        color: lighten($color-translator, 10%);
    }
}
