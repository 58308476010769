::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    background-color: #ccc;
}
::-webkit-scrollbar-track {
    border-radius: 1rem;
    background-color: #ccc;
    -webkit-box-shadow: inset 0 0 0.6rem rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: $color-accent;
    -webkit-box-shadow: inset 0 0 0.6rem rgba(0,0,0,0.5);
    cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
    background-color: $color-primary;
}
