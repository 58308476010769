cp-table {

    position: relative;
    width: 100%;
    border-bottom: #eee 1px solid;
    overflow: auto;


    .table {
        border: #ccc solid;
        border-width: 1px 0 0 0;

        .md-virtual-repeat-scroller {
            overflow-y: scroll;
        }

        .thead {
            background: #e0e0e0 !important;
            border-bottom: #bbb 1px solid;
        }

        .tr {
            .th,
            .td {
                position: relative;
                padding: 3px 5px;
                vertical-align: top;
                font-weight: normal;
                border: #ccc solid;
                border-width: 0 1px 1px 0;
                background-color: white;

                &:first-of-type {
                    border-left-width: 1px;
                }
            }

            &:nth-of-type(odd) {
                .td {
                    background-color: #f5f5f5;

                    &.editable {
                        background-color: #B2DFDB;
                    }

                    &.deletable {
                        background-color: #DFB2B6;
                    }

                    &.deleted {
                        background-color: #C97C83;
                    }
                }
            }
            &:hover {
                .td {
                    background-color: #e0e0e0 !important;
                }
            }


            .th {
                padding: 0 20px;
                text-align: center;
                line-height: 2.4rem;
                color: black;
                background: #e0e0e0;
                white-space: nowrap;
                border-bottom: none;
                text-transform: capitalize;

                &.fixed {
                    z-index: 20;
                }

                & > a {
                    text-decoration: underline;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    &[disabled] {
                        cursor: default;
                        text-decoration: none;
                    }
                }

                .md-icon-button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 0;
                    padding: 0;
                    width: 24px;
                    min-width: 0;
                    height: 24px;
                    min-height: 0;
                }


                md-fab-speed-dial {
                    position: absolute;
                    top: 0;
                    right: -6px;
                    z-index: 0;

                    &.md-is-open {
                        z-index: 20;
                    }


                    .md-button.md-fab {
                        margin: 0;
                        padding: 0;
                        width: 30px;
                        height: 30px;
                        min-height: 30px;
                        line-height: 30px;
                    }

                    md-fab-trigger {
                        .md-button.md-fab {
                            position: relative;
                            top: -3px;

                            background: none;
                            box-shadow: none;

                            md-icon {
                                color: $color-accent;
                            }
                        }
                    }
                }

                .md-icon-button,
                md-fab-actions .md-button {
                    overflow: visible;

                    &.md-fab {
                        margin: 4px 0;
                    }

                    md-icon {
                        color: inherit;
                    }

                    .cp-tooltip {
                        top: calc(100% + 8px);
                        right: 0;
                        width: 400px;
                    }

                }

                &:first-of-type {
                    .md-icon-button,
                    md-fab-actions .md-button.md-fab {
                        .cp-tooltip {
                            left: 0;
                            right: auto;
                            &:after {
                                left: 10px;
                                right: auto;
                            }
                        }
                    }
                }
            }

            .td {
                &.fixed {
                    z-index: 10;
                }

                & > div {
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                a,
                &.link {
                    text-decoration: underline;
                    cursor: pointer;

                    &[disabled] {
                        text-decoration: none;
                        cursor: default;
                    }
                }

                @mixin edit-delete-styles {
                    position: relative;

                    & > textarea.dynamic,
                    & > input.dynamic {
                        display: block;
                        border: none;
                        outline: none;
                        box-sizing: border-box;

                        &.invalid {
                            border: #ff8a80 2px solid;
                            border-radius: 2px;
                        }
                    }
                    & > textarea.dynamic {
                        margin: -0.3rem -0.5rem !important;
                        width: calc(100% + 1rem);
                        height: calc(100% + 1rem);
                        min-height: 40px;
                    }
                    & > input.dynamic {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                    }

                    & > .input-help {
                        padding: 4px;
                        position: absolute;
                        top: calc(100% + 4px);
                        right: 0;
                        z-index: 1;
                        color: white;
                        background: rgba(0,0,0,.8);
                        border-radius: 2px;
                        box-shadow: 0 1px 4px rgba(0,0,0,.26);
                        overflow: visible;

                        &:after {
                            content: '';
                            position: absolute;
                            border-style: solid;
                            border-width: 0 4px 4px;
                            border-color: rgba(0,0,0,.8) transparent;
                            display: block;
                            width: 0;
                            z-index: 10;
                            top: -4px;
                            right: 8px;
                        }

                    }

                    md-autocomplete {
                        height: 18px;
                        border-radius: 0;
                        background: none;

                        md-autocomplete-wrap {
                            height: 18px;
                            box-shadow: none;

                            input {
                                padding: 0;
                                height: 18px;
                                font-size: 1.2rem;
                            }
                        }

                        button {
                            margin: 0;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                &.editable {
                    @include edit-delete-styles;
                    background: #E0F2F1;

                    &:hover {
                        background: #80CBC4 !important;
                    }
                }

                &.deletable {
                    @include edit-delete-styles;
                    background: #F2E0E1;

                    &:hover {
                        background: #CB8087 !important;
                    }
                }

                &.deleted {
                    @include edit-delete-styles;
                    background-color: #C97C83;

                    &:hover {
                        background: #B54A54 !important;
                    }
                }

                &.edited {
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-style: solid;
                        border-width: 0 10px 10px 0;
                        border-color: transparent rgba(255, 138, 128, .66) transparent transparent;
                    }

                }
                &.number {
                    text-align: right;
                }
                &.align-center {
                    text-align: center;
                }

                &.yellow-color {
                    color: rgb(243, 154, 10) !important;
                }
                &.orange-color {
                    color: rgb(243, 154, 10) !important;
                }
                &.red-color {
                    color: red !important;
                }
                &.cyan-color {
                    color: rgb(0, 215, 239) !important;
                }
                &.green-color {
                    color: rgb(60,118,61) !important;
                }
                &.checkup-border {
                    border-bottom: 4px solid red !important;
                }

                &.type-md-icon-button,
                &.type-md-icon-button-safe-accept,
                &.type-inline-action,
                &.type-cp-progress-download {
                    padding: 0;
                    min-width: 0;
                    width: 32px;
                    text-align: center;

                    .md-icon-button {
                        margin: 0;
                        padding: 0;
                        width: 24px;
                        height: 24px;
                        min-height: 0;
                        line-height:24px;
                    }
                }

                &.type-cp-progress-download {
                    md-progress-linear {
                        .md-container,
                        .md-bar {
                            height: 10px;
                        }
                    }
                }

                [contenteditable] {
                    margin: -0.3rem -0.5rem;
                    padding: 0.3rem 0.5rem;
                    outline: 0;
                }

                md-switch {
                    margin: -0.3rem -0.5rem !important;
                }

                md-select {
                    margin: -0.3rem -0.5rem;

                    &.md-default-theme .md-select-value,
                    .md-select-value {
                        min-height: 0;
                        border: none;
                    }
                }

                md-checkbox {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    .md-container {
                        left: 50%;
                        margin-left: -6px;
                    }

                }

                .cp-switch-label {
                    margin: 2px auto 8px auto;
                }
                [cp-editable],
                [cp-autocomplete] {
                    width: 100%;
                    min-height: 1.8rem;
                    line-height: 1.8rem;

                    & + [type="number"] {
                        text-align: right;
                    }
                }
                [cp-autocomplete] {
                    & > .md-icon-button {
                        position: absolute;
                        right: 0;
                        width: 24px;
                        height: 24px;
                        top: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;
                        min-height: 0;

                        md-icon {
                            font-size: 2.4rem;
                            color: rgba(0,0,0,0.54);
                            width: 24px;
                            height: 24px;
                        }
                     }
                }
                &.type-md-icon-button-dialog {
                    padding: 0px;
                    & > .md-icon-button {
                        padding: 0px 30px;
                        width: 24px;
                        height: 24px;
                        min-height:24px;
                    }
                }
            }
        }
    }
}
