// Angular Material overwrites
md-sidenav {
    md-toolbar {
        min-height: 30px;

        .md-toolbar-tools {
            height: 30px;
            font-size: 1.6rem;
        }

        .md-button.md-icon-button {
            padding: 0;
            width: 30px;
            height: 30px;
            min-height: 30px;

            md-icon {
                color: inherit;
            }
        }
    }
}

md-toast.md-top {
    z-index: 999999;
}

md-backdrop {
    &.md-select-backdrop,
    &.md-menu-backdrop,
    &.md-dialog-backdrop{
        z-index: 9010 !important;
    }
    &.md-select-backdrop {
        background-color: rgba(0,0,0,.26);
    }
    &.md-select-backdrop {
        z-index: 10001!important;
    }
}

.md-panel-outer-wrapper.md-panel-is-showing {
    z-index: 9010 !important;
}
.md-select-menu-container {
    z-index: 10002 !important;

    .md-button {
        margin: 0;
        padding: 4px 16px;
        width: 100%;
        text-align: left;
    }

    md-option.md-accent {
        &:hover,
        &:focus,
        &:not([disabled]):hover,
        &:not([disabled]):focus {
            color: rgb(66,66,66) !important;
            text-decoration: underline;
            background: none;
        }
        &[selected] {
            &.md-accent {
                background-color: #E0E0E0;
            }
        }

        &.editable {
            &,
            &:hover,
            &:focus {
                background-color: #E0F2F1;
            }

            &[selected] {
                background-color: #80CBC4;
            }
        }
    }
}


.md-open-menu-container {
    z-index: 9010 !important;
}

md-select {
    &.md-default-theme .md-select-label {
        padding: 0;

        span {
            display: block;
            width: 10rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &.md-select-icon {
                width: auto;
                margin-left: 0;
            }
        }
    }

    &[disabled] {
        .md-select-value {
            color: inherit;
            background: none;
        }
        .md-select-icon {
            display: none;
        }
    }
}

// TMP workaround to fix missing md-select floating label
md-input-container {
    &.md-default-theme label {
        font-weight: 400;
        color: rgba(0,0,0,.4);
    }
    md-select {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        display: block;
        background: none;
        padding: 0.2rem 0.2rem 0.1rem;
        min-height: 2.6rem;
        line-height: 2.6rem;
        -ms-flex-preferred-size: 2.6rem;
        border-width: 0 0 0.1rem 0;
        border-radius: 0;
        margin: 0;

        .md-placeholder {
            opacity: 1;
            left: 0;
            right: 0;

        }
    }


    .md-errors-spacer {
        min-height: 0;
    }

    .md-input[disabled] {
        color: rgba(0,0,0,0.75);
    }
    input[type='number']{
        -moz-appearance: textfield;
    }
    md-autocomplete{
        margin-top: -2rem;
    }
}

md-menu-item > .md-button {
    display: block !important;
    text-transform: capitalize !important;
}

md-tooltip {
    z-index: 9990 !important;
    text-transform: capitalize;

    p {
        margin: 0;
    }

    .md-content {
        height: auto;
        white-space: pre-wrap;
    }
}

.md-autocomplete-suggestions,.md-autocomplete-suggestions-container {
    z-index: 9999 !important;
}

md-autocomplete.md-accent md-input-container.md-input-focused {
    label {
        color: rgb(158,158,158);
    }
    .md-input {
        border-color: rgb(158,158,158);
    }
}
md-autocomplete[md-floating-label] {
    padding-top: 18px;

    md-input-container {
        margin-top: 0;
    }
}
.md-dialog-container {
    z-index: 10000 !important;
}

md-tabs.md-default-theme .md-tab.md-active, md-tabs.md-default-theme .md-tab.md-focused {
    color: black !important;
}
md-content {
    cursor: auto;
    width: 100%;

    md-progress-linear[md-mode=indeterminate] .md-container {
        top: 0;
    }

    form {
        md-grid-tile {
            text-align: left;

            md-autocomplete {
                //margin-top: -2rem!important;
            }
        }
    }

    .message {
        padding: 0 1rem;
        color: black;
    }

    md-switch {
        margin: 0;
        .md-container {
            cursor: grab;
            width: 2.4rem;
            height: 1.6rem;
            position: relative;
            -moz-user-select: none;

        }
        .md-bar {
            left: 0.1rem;
            width: 3.4rem;
            top: 0.5rem;
            height: 0.6rem;
            border-radius: 0.8rem;
            position: absolute;
            background: lightgray;
        }
        .md-thumb {
            width: 1.2rem;
            height: 1.2rem;
            background: #848484;
        }
        .md-thumb-container {
            width: 2.6rem;
        }

        .md-label {
            padding-left: 1rem;
        }

        &.md-checked{
            .md-thumb {
                background: #FDD835;
            }
            .md-bar {
                background: #FEEFAE;
            }
        }

        &[disabled] {
            cursor: not-allowed;

            .md-container,
            .md-container {
                cursor: not-allowed;
            }
        }

    }
}

.md-chips {
    padding-bottom: 1px;

    .md-chips .md-chip-input-container {
        margin-top: 0;
    }
}

// md-select value overflow bug
.md-select-value :first-child {
    max-width: calc(100% - 25px);
}

md-checkbox {
    margin-bottom: 0;
    min-height: 20px;
    min-width: 20px;

    .md-container {
        top: 0;
        -ms-transform: scale(0.6); /* IE */
        -moz-transform: scale(0.6); /* FF */
        -webkit-transform: scale(0.6); /* Safari */
    }
}

// material date picker
.dtp .dtp-close>a,
.dtp >.dtp-content>.dtp-date-view>header.dtp-header {
    color: $color-accent;
    background: darken($color-primary, 15%);
}

.dtp div.dtp-year-btn,
.dtp div.dtp-actual-year {
    color: lighten($color-accent, 15%);
}

.dtp div.dtp-date,
.dtp div.dtp-time,
.dtp table.dtp-picker-days tr>td>a.selected,
.dtp .dtp-picker-time>a.dtp-select-hour.selected,
.dtp .dtp-hand.on {
    color: $color-accent;
    background: $color-primary;
}

div.md-list-item-text.layout-column {
    margin: 0px;
}

md-list-item.md-2-line.md-long-text,
md-list-item.md-2-line.md-long-text:before {
    margin: 0px;
    min-height:18px;
}
