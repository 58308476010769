// Material colors
// -----------------------------------
$color-primary:         #fdd835;
$color-accent:          #424242;

// Body
// -----------------------------------
$body-bg:               #f1f2f7 !default;

// Buttons
// -----------------------------------
$btn-default-color:     #fff !default;
$btn-default-bg:        #bec3c7 !default;
$btn-default-border:    $btn-default-bg !default;

$btn-primary-color:     #000 !default;

// Navigation
// -----------------------------------
$navbar-height:         3rem !default;

// Fonts
// -----------------------------------
$font-size-base:        1.3rem !default;
$font-size-large:       ceil(($font-size-base * 1.25)) !default;
$font-size-small:       ceil(($font-size-base * 0.7)) !default;
$font-size-h1:          floor(($font-size-base * 2.6)) !default;
$font-size-h2:          floor(($font-size-base * 2.15)) !default;
$font-size-h3:          ceil(($font-size-base * 1.7)) !default;
$font-size-h4:          $font-size-large !default;
$font-size-h5:          $font-size-base  !default;
$font-size-h6:          $font-size-small !default;

// Colors
// -----------------------------------
$color-shadow:      rgba(0,0,0,.5);
$color-light:       lighten($color-shadow, 100%);
$color-fill-dark:	#1F1F1F;
$color-fill-light:	#F1F1F1;


$grid-gutter-width: 3rem;

$breakSm:           600px;
$breakMd:           960px;